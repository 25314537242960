import React from "react";
import "./Section2.css";
import Fade from "react-reveal/Fade";
import { Link } from "react-router-dom";
import { Link as LinkRoll } from "react-scroll";

import wrapper1 from "../../assets/wrapper1.webp";

const Section2 = () => {
  return (
    <>
      <div className="section2 ">
        <div className="section2-content container">
          
        <Fade left>
          <div className="section2-content-right">
            <h1>MENTAL HEALTH</h1> <br />
            <h2>
              <i>"Start your journey today—your better tomorrow begins now."</i>
            </h2>
            <br />
            <p>
            At Beach Street Rehab, our comprehensive Mental Health Treatment Center is dedicated to addressing the vital connection between mental well-being and overall health. We recognize that every recovery journey is unique, which is why we offer personalized mental health treatment tailored to individual needs.
            </p><br />
            <p>Our evidence-based therapies include individual counseling, group support sessions, and holistic mental health care to accommodate different preferences and comfort levels. Our experienced practitioners stay at the forefront of mental health research and treatment innovations, ensuring you receive the most effective care available.</p><br />
            <p>Take the first step toward a healthier, happier life by exploring our specialized programs and scheduling a consultation with one of our compassionate experts today. At Beach Street Rehab, you are never alone—we're here to support you every step of the way toward lasting recovery and emotional well-being.</p>
            <br />
            <div className="hide">
                    <LinkRoll activeClass="active" to="top" spy={true} smooth={true} duration={500}>
                      <Link to="/mental-health">
                        <button>Start Your Journey</button>
                      </Link>
                    </LinkRoll>
                  </div>

                  <div className="show">
                    <LinkRoll activeClass="active" to="scroll" spy={true} smooth={true} duration={500}>
                      <Link to="/mental-health">
                        <button>Start Your Journey</button>
                      </Link>
                    </LinkRoll>
                  </div>
          </div>
          </Fade>

          <Fade right>
          <div className="section2-content-left">
            <img src={wrapper1} alt="Mental Health Therapy Session" loading="lazy" />
          </div>
          </Fade>


        </div>
      </div>
    </>
  );
};

export default Section2;
