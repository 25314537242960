import React from 'react'
import "./Component3.css";

import c3Img from "../../assets/mh-mainmenu-bg-min.webp";

const Component3 = () => {
  return (
    <>
      
      {/* MENTAL HEALTH SERVICES */}
      <div className="component3 container">

        <div className='component3-header'>
          <h1>Beach Street Rehab</h1>
          <p>Comprehensive Addiction & Mental Health Treatment in Orange County</p>
        </div>

          <div className="component3-content container">

          <div className="c3-left">

          <h3>Personalized Care for Substance Abuse & Mental Health</h3>
          <p>Beach Street Rehab offers evidence-based addiction treatment  and mental health recovery programs designed to meet your unique needs. Whether you require the flexibility of our  Intensive Outpatient Program (IOP) or the structured support of our  Partial Hospitalization Program (PHP) , our expert team is here to guide you every step of the way.</p>


          <h3>Tailored Treatment for Long-Term Success</h3>
          <p>From day one, every client receives a  customized recovery plan , carefully designed to address their specific challenges. Within the first  30 days of admission, we establish a comprehensive treatment roadmap, with  weekly progress evaluations  led by our certified drug and alcohol counselors.</p>

          <p>Each treatment plan is continuously reviewed and approved by our  clinical director, ensuring  high-quality, goal-driven care  that fosters sustainable recovery.</p>

          <h3>Start Your Recovery Today</h3>
          <p>If you or a loved one is struggling with addiction or mental health challenges,  Beach Street Rehab is here to help . Contact us today to learn more about our programs and take the first step toward a healthier, happier future.</p>


          </div>




          <div className="c3-right">

            
            <div className='c3-right-img'>
              <img src={c3Img} alt="nurse" loading="lazy" />
            </div>
            

          </div>
          </div>
        </div>


    </>
  )
}

export default Component3
